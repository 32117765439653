export const donutPickItems = [
  {
    name: "Assorted Dozen",
    classics: 6,
    premiums: 4,
    inspireds: 2,
  },
  {
    name: "Half Dozen",
    classics: 3,
    premiums: 2,
    inspireds: 1,
  },
  {
    name: "Gift Box",
    classics: 5,
    premiums: 4,
    inspireds: 0,
    donutHoles: 20,
  },
  {
    name: "Leap Year Box",
    classics: 6,
    premiums: 4,
    inspireds: 2,
  },
];

export const donutDropdownItems = [
  "Donut Blendee",
  "Donut Sandwich Van.",
  "Donut Sandwich Choc.",
];
