import { useEffect, useState } from "react";
import { Dropdown } from "../../Common/Components/Dropdown";
import List from "../../Common/Components/List";
import MultiListDropdown from "../../Common/Components/MultiListDropdown";
import { setMenuItem } from "../../store/actions";
import withLocalContext from "../../store/withLocalContext";

const ModifierVals = ({
  context: { dispatch },
  modsToRender,
  selectedMods,
}) => {
  const [dropdown, setDropdown] = useState([]);
  const [singleList, setSingleList] = useState([]);
  const [multiList, setMultiList] = useState([]);
  const [multiListDropdown, setMultiListDropdown] = useState([]);

  useEffect(() => {
    setMods();
    // eslint-disable-next-line
  }, []);

  const setMods = () => {
    const tempDropdown = [];
    const tempSingleList = [];
    const tempMultiListDropdown = [];
    const tempMultiList = [];
    modsToRender.map((mod) => {
      mod.catId = mod.modifierListId;
      mod.category = mod.modifiers[0]?.listName;
      mod.initialOpts = "";
      if (mod.modifierOverrides) {
        const initValOverides = mod.modifierOverrides.filter(
          (item) => item.onByDefault === true
        );
        if (initValOverides.length > 0) {
          const initdefaultVals = mod.modifiers.filter((mod) => {
            return initValOverides.some((item) => mod.id === item.modifierId);
          });
          mod.initialOpts = initdefaultVals;
        }
      }

      // this code comes into play if an item has been put in the cart and is being modified
      // from its original selections.
      if (selectedMods) {
        selectedMods.map((selMod) => {
          if (selMod.category === mod.category) {
            if (Array.isArray(selMod.modifiers[0])) {
              mod.initialOpts = selMod.modifiers[0];
            } else {
              mod.initialOpts = selMod.modifiers;
            }
          }
          return selMod;
        });
      }
      // show dropdown
      if (mod.selectionType === "SINGLE") {
        // if dropdwon does not have a defualt setting then have the top option be the default
        if (mod.initialOpts === "") {
          mod.initialOpts = [mod.modifiers[0]];
          dispatch(setMenuItem([mod.modifiers[0]]));
        } else {
          dispatch(setMenuItem([mod.initialOpts[0]]));
        }

        tempDropdown.push(mod);
      } else {
        // show singlelist
        if (mod.maxSelectedModifiers === 1) {
          tempSingleList.push(mod);
          if (mod.initialOpts) {
            dispatch(setMenuItem([mod.initialOpts[0]]));
          }

          // show multi list dropdown
        } else if (
          (mod.minSelectedModifiers === -1 || 1) &&
          mod.maxSelectedModifiers > 1
        ) {
          tempMultiListDropdown.push(mod);
          if (mod.initialOpts) {
            dispatch(setMenuItem([mod.initialOpts]));
          }
          // show multi list
        } else if (
          mod.minSelectedModifiers === -1 &&
          mod.maxSelectedModifiers === -1
        ) {
          tempMultiList.push(mod);
          if (mod.initialOpts) {
            dispatch(setMenuItem([mod.initialOpts]));
          }
        }
      }
      return mod;
    });
    setDropdown(tempDropdown);
    setSingleList(tempSingleList);
    setMultiListDropdown(tempMultiListDropdown);
    setMultiList(tempMultiList);
  };

  return (
    <div>
      {dropdown.map((opt) => (
        <Dropdown
          key={opt.modifierListId}
          initialOpt={opt.initialOpts === "" ? "" : opt.initialOpts[0]}
          catId={opt.catId}
          category={opt.category}
          options={opt.modifiers}
          selectedValue={(value) => dispatch(setMenuItem(value))}
        />
      ))}

      {singleList.map((opt) => (
        <List
          key={opt.modifierListId}
          initialOpt={opt.initialOpts === "" ? "" : opt.initialOpts[0]}
          category={opt.category}
          options={opt.modifiers}
          singleSelect={true}
          selectedValue={(value) => dispatch(setMenuItem(value))}
        />
      ))}
      {multiList.map((opt) => (
        <List
          key={opt.modifierListId}
          initialOpt={
            selectedMods && opt.initialOpts.length === 1
              ? opt.initialOpts[0]
              : opt.initialOpts === ""
              ? ""
              : opt.initialOpts
          }
          category={opt.category}
          options={opt.modifiers}
          singleSelect={false}
          selectedValue={(value) => dispatch(setMenuItem(value))}
        />
      ))}
      {multiListDropdown.map((opt) => (
        <MultiListDropdown
          key={opt.modifierListId}
          initialOpts={opt.initialOpts === "" ? "" : opt.initialOpts}
          category={opt.category}
          options={opt.modifiers}
          maxSelectedModifiers={opt.maxSelectedModifiers}
          minSelectedModifiers={opt.minSelectedModifiers}
          selectedValue={(value) => dispatch(setMenuItem(value))}
        />
      ))}
    </div>
  );
};
export default withLocalContext(ModifierVals);
